import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "./Breadcrumbs";
import { useParams, Link } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { isEmpty } from "lodash";
import ReactPaginate from "react-paginate";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
const CommissionHistory = () => {
  const params = useParams();
  let { user } = useContext(AuthContext);
  const [getLogData, setLogData] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [search_params, setSearchParams] = useState({
    page: 1,
    limit: 100,
  });
  const myStatementData = async () => {
    search_params.user_id = params?.id;
    const { status, data: response_users } = await apiGet(
      apiPath.commissionLogs,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        setLogData(response_users.results);
      }
    }
  };

  const handlePageClick = (event) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
  };

  useEffect(() => {
    setPageCount(getLogData?.totalPages || []);
  }, [getLogData]);

  useEffect(() => {
    myStatementData();
  }, [search_params]);
  // console.log("getLogData",getLogData)
  return (
    <div>
      <section className="py-4 main-inner-outer">
        <Container fluid>
          <Breadcrumbs user_id={params?.id} />
          <div className="accout_cols_outer">
            <div className="left_side">
              <Sidebar />
            </div>
            <div className="right_side">
              <div className="inner-wrapper">
                <section className="account-table">
                  <h6>Totall Approved Amount : {getLogData?.totalApproveAmount}</h6>
                  <h6>Totall Declined Amount : {getLogData?.totalDeclineAmount}</h6>
                  <div className="responsive transaction-history">
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">Date/Time</th>
                          <th scope="col"> Credit  </th>
                          <th scope="col"> Debit  </th>
                          <th scope="col">Remark</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {getLogData.data &&
                          getLogData.data.map((item, key) => (
                            <tr>
                              <td>
                                {helpers.dateFormat(
                                  item?.createdAt,
                                  user?.timeZone
                                )}
                              </td>
                              <td>
                                {item?.transactionType === "credit" ? (
                                  <span className="text-success">
                                    {helpers.currencyFormat(item?.amount)}
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                             
                              <td>
                                {" "}
                                {item?.transactionType === "debit" ? (
                                  <span className="text-danger">
                                    {"(" +
                                      helpers.currencyFormat(item?.amount) +
                                      ")"}
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                              
                              
                             
                              <td>
                                  {item?.remark }{item?.remark=="Commission"?"-"+  item?.TransactionDate:""}
                              </td>
                             
                            </tr>
                          ))}
                        {isEmpty(getLogData.data) ? (
                          <tr>
                            <td colSpan={9}>No records found</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </Table>
                    <div className="bottom-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=" >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< "
                        renderOnZeroPageCount={null}
                        activeClassName="p-1"
                        activeLinkClassName="pagintion-li"
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default CommissionHistory;
